/* === CSS VARIABLES === */
:root {
  --greenColor: #224732;
  --greenTransparant: rgb(0 233 100 / 50%);
  --redColorLight: #BF352F;
  --redColorDark: #861812;
  --redTransparant: rgba(191, 53, 47, 0.5);
  --whiteColor: #fff;
  --whiteTransparant: rgba(255,255,255,0.75);
  --beigeColor: #FFD99B;

  --borderTop: 1px solid rgba(255,255,255,0.5);
  --smallShadow: 0 1px 1px rgba(0,0,0,0.11),
  0 2px 2px rgba(0,0,0,0.11),
  0 4px 4px rgba(0,0,0,0.11),
  0 6px 8px rgba(0,0,0,0.11),
  0 8px 16px rgba(0,0,0,0.11);
  --bigShadow: 0 1px 2px rgba(0,0,0,0.1),
  0 2px 4px rgba(0,0,0,0.1),
  0 4px 8px rgba(0,0,0,0.1),
  0 8px 16px rgba(0,0,0,0.1),
  0 16px 32px rgba(0,0,0,0.1),
  0 32px 64px rgba(0,0,0,0.1);
}

/* === GOOGLE FONTS === */
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@400;700&display=swap');
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  background: var(--greenColor);
  background-repeat: no-repeat;
  float: left;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* === FORM === */
.formDiv{
  display: grid;
  row-gap: 20px;
  z-index:11;
}
.goede_doelen__title {
  font-family: "Yeseva One", cursive;
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--whiteColor);
  text-align: center;
}
.goede_doelen__subtitle {
  margin-bottom: 10px;
  font-family: 'Roboto Condensed', sans-serif;
  color: var(--whiteTransparant);
  text-align: center;
}
.goede_doelen__cardcontainer {
  display: grid;
  row-gap: 10px;
}
.goede_doelen__cardcontainer .goed_doel_card {
  font-family: 'Roboto', sans-serif;
  position: relative;
  display: grid;
  grid-template-rows: 60px 1fr;
  row-gap: 10px;
  justify-items: center;
  padding: 15px 20px;
  background-color: var(--greenTransparant);
  border-radius:30px;
  box-shadow: var(--smallShadow);
  border-top: var(--borderTop);
  color: var(--whiteTransparant);
  text-align: center;
}
.goede_doelen__cardcontainer .goed_doel_card img {
  align-self: center;
  z-index: 10;
}
.goede_doelen__cardcontainer .goed_doel_card input {
  position: absolute;
  border:0px;
  outline:0px;
}
.goede_doelen__cardcontainer .goed_doel_card label {
  position: relative;
  justify-items: center;
  padding: 3px 20px;;
  background-color: var(--redColorDark);
  border-radius: 5px;
  border: none;
  box-shadow: var(--smallShadow);
  color: white;
  text-align: center;
}
.goede_doelen__cardcontainer .goed_doel_card a {
  display: block;
  padding: 5px;
  margin-top: 10px;
}
.goed_doel_logo {
  max-width: 160px;
  max-height: 60px;
}
.radio.select{
  appearance: none;
  position: absolute;
  padding: 8px;
  width: 100%;
  height: 100%;
  margin: 0;
}
.radio.select:checked{
  border: 5px solid var(--greenColor);
  border-radius: 30px;
  background-color: var(--redTransparant);
}
.radio.select:checked:before {
  content: url("./images/kerstbal.png");
  filter: drop-shadow(1px 10px 8px rgba(0,0,0,0.36));
}
.address {
  display: grid;
  row-gap: 5px;
}
.address__input_container {
  display: grid;

}
.address label {
  margin-bottom: 5px;
  padding-left: 10px;
  font-family: 'Roboto', sans-serif;
  color: var(--whiteColor);
}
.address .email {
  box-sizing: border-box;
  width: auto;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 50px;
  border: none;
  font-family: "Yeseva One", cursive;
  font-size: 16px;
  color: var(--greenColor)
}
.error {
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  color: var(--whiteColor);
  text-align: center;
  background-color: var(--redColorDark);
  padding: 15px;
  border-radius: 20px;
}
.succes {
  border: 2px solid;
  padding: 10px 15px;
  border-radius: 30px;
  width: max-content;
  margin: 0 auto;
  background: green;
  font-size: 26px;
}
.button {
  box-sizing: border-box;
  padding: 10px;
  background-color: var(--beigeColor);
  border-radius: 50px;
  border-top: var(--borderTop);
  color: var(--redColorLight);
  font-family: "Yeseva One", cursive;
  font-size: 24px;
  box-shadow: var(--smallShadow);
  border: none;
}
.read-more-state {
  display: none;
}
.read-more-target {
  max-height: 5em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  transition: .25s ease;
  background: -webkit-linear-gradient(var(--whiteColor) 60%, #fff0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.read-more-wrap {
  z-index: 10;
}
.read-more-state:checked ~ .read-more-wrap .read-more-target {
  background: none;
  -webkit-background-clip: border-box;
  -webkit-text-fill-color: var(--whiteColor);
  max-height: 999em;
}
.read-more-state ~ .read-more-trigger:before {
  content: 'Lees meer';
}
.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Lees minder';
}
.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  color: #666;
  font-size: .9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: .25em;
}

/* === MEDIA QUERIES === */
@media only screen and (min-width: 550px) {
  .formDiv{
    padding: 0 45px;
  }
  .goede_doelen__cardcontainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, max-content);
    grid-auto-flow: row dense;
    gap: 10px;
  }
  .goede_doelen__subtitle {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .formDiv{
    padding: 0 80px;
  }
}
@media only screen and (min-width: 1024px) {
  .goede_doelen__title {
    font-size: 40px;
  }
  .goede_doelen__subtitle {
    font-size: 20px;
  }
  .address__input_container {
    grid-template-columns: 3fr 1fr;
    column-gap: 10px;
    place-items: center start;
  }
  .address .email {
    margin: 0;
    width: 100%;
  }
  .formDiv{
    row-gap: 45px;
  }
  .button {
    width: 100%;
  }
  .error {
    width: max-content;
    justify-self: center;
    box-shadow: var(--smallShadow);
  }
  .goede_doelen__cardcontainer {
    gap: 20px;
  }
}
@media only screen  and (min-width : 1224px) {
  .goede_doelen__cardcontainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, max-content);
    gap: 30px;
  }
  .goede_doelen__title {
    font-size: 46px;
  }
  .goede_doelen__subtitle {
    margin-bottom: 35px;
  }
}
