.App {
  display: grid;
}

/* === MEDIA QUERIES === */
 @media only screen and (min-width: 550px) {
  .App {
    padding: 30px 30px 50px 30px;
  }
}
/*
@media only screen and (min-width: 768px) {
  .App {
    padding: 30px 60px 80px 60px;
  }
}
@media only screen and (min-width: 1024px) {
  .App {
    padding: 30px 100px 100px 80px;
  }
}
@media only screen  and (min-width : 1224px) {
  .App {
    width: 100%;
  }
} */


