.labels_container {
    display: grid;
    justify-self:center;
    grid-template-rows: max-content max-content;
    row-gap:8px;
    width: 100%;
    margin-top: 30px;
}
.labels_container__title {
    margin-bottom: 10px;
    font-family: "Yeseva One", cursive;
    font-size: 18px;
    color: var(--whiteTransparant);
    text-align: center;
}
.labels_container__labels {
    display: grid;
    place-items: center center;
    gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}
.labels_container__labels img{
    max-width: 120px;
}

/* === MEDIA QUERIES === */
@media only screen and (min-width: 768px) {
    .labels_container {
        width: 100%;
    }
}
@media only screen  and (min-width : 1224px) {
    .labels_container {
        max-width: 1100px;
    }
}