.card {
    box-sizing: border-box;
    display: grid;
    justify-self:center;
    grid-template-rows: max-content 1fr;
    row-gap: 10px;
    width: 100%;
    padding: 15px 25px 30px 25px;
    background: rgb(158,27,33);
    background-image: url('./images/kerstboom-links.png'), linear-gradient(356deg, #911c16 0%, #BF352F 100%);
    background-repeat: no-repeat;
    background-position: left bottom;
    border-radius: 35px;
    border-top: var(--borderTop);
    box-shadow: var(--bigShadow);
    overflow: hidden;
}
.card__logo {
    text-align: center;
}
.card__logo img{
    height: 50px;
    object-fit: contain;
}
.card__title {
    font-size: 26px;
    font-weight: bold;
    color: #fff;
}

/* === MEDIA QUERIES === */
@media only screen and (min-width: 550px) {
    .card {
        row-gap: 20px;
        padding: 15px 25px 30px 25px;
    }
    .card__logo {
        text-align: left;
    }
}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 1024px) {
    .card {
        padding: 20px 30px 45px 30px;
    }
}
@media only screen  and (min-width : 1224px) {
    .card {
        max-width: 1100px;
        background-image: url('./images/twee-kerstbomen.png'), linear-gradient(356deg, #911c16 0%, #BF352F 100%);
        border-radius: 50px;
    }
    .card__logo img{
        height: 70px;
        object-fit: contain;
    }
}